.paper {
  padding: calc(var(--theme-spacing) * 2);
  text-align: center;
  color: var(--theme-palette-text-primary);
}

button.reloadButton {
  margin-left: 10px;
  background-color: red;
}
