@value menudrawerwidth: 200px;
@value phonedrawerwidth: 400px;

header.appBar {
  height: 65px;
  z-index: 1100;
  align-items: left;
}

header.appBarStaging {
  background-color: #e11d21;
}

header.appBarLocal {
  background-color: darkgray;
}

.appBarShift {
  width: calc(100% - menudrawerwidth);
  margin-left: menudrawerwidth;
}

.appBarPhoneShift {
  width: calc(100% - phonedrawerwidth);
  margin-right: phonedrawerwidth;
}

.menuButton {
  margin-right: 0;
  margin-left: 0;
}

.hide {
  display: none;
}

.appVersion {
  min-width: 120px;
  margin-top: 35px;
  margin-right: auto;
}

.headerGridContainer {
  margin-left: 75px;
  margin-right: auto;
}

.logo {
  height: 65px;
}

.offline {
  background-color: red;
}

.available {
  background-color: green;
}

.currentUser {
  background-color: blue;
}
